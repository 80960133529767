import React from 'react';
import { Router } from '@reach/router';
import OrganizationDashboard from 'components/OrganizationDashboard/OrganizationDashboard';

const basepath ='organization/dashboard';
function dashboard() {
  return (
    <Router basepath={basepath}>
      <OrganizationDashboard basepath={basepath} path=":orgId" />
      <OrganizationDashboard  basepath={basepath} path=":orgId/:tab" />
    </Router>
  );
}

dashboard.propTypes = {};

export default dashboard;
