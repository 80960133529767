import { useQuery } from '@apollo/client';
import LongEventCard from 'components/LongEventCard';
import StatusCard from 'components/StatusCard';
import { Box, Heading, Select, Spinner, Text } from 'grommet';
import useResponsive from '../../../hooks/useResponsive';
import React from 'react';
import { IEvent } from 'services/events';
import { OrganizationService, IOrganization } from 'services/organizations';
import { Button } from 'tutadoo-design-system/src/components/Button';
import { TabContainer } from './common';
import { navigate } from '@reach/router';

const EventsTab = ({ organizationInfo }: { organizationInfo: IOrganization }) => {
  const [filterValue, setFilterValue] = React.useState('All events');
  const { data, loading } = useQuery<{ events: IEvent[] }>(
    OrganizationService.getAllOrgEvents(organizationInfo?.id)
  );
  const { screen } = useResponsive();

  return (
    <TabContainer>
      <div className="flex justify-between w-full items-center my-6 flex-wrap">
        <Heading level={'3'} size="small">
          Your events
        </Heading>
        <Box direction="row" className="mt-4 sm:mt-0">
          <Select
            options={['All events', 'Published', 'Draft', 'Past']}
            value={filterValue}
            onChange={({ option }) => setFilterValue(option)}
            margin={{ right: '10px' }}
            className=" h-10 sm:h-auto"
          />
          <Button
            className="my-4 sm:my-0"
            size="large"
            primary
            label={screen.isMobile ? '+' : 'Create Event'}
          />
        </Box>
      </div>
      <Box>
        {loading ? (
          <Box height={{ min: '200px' }} justify="center" align="center">
            <Spinner />
            <Text textAlign="center">Fetching Organization events...</Text>
          </Box>
        ) : !!!data?.events?.length ? (
          <StatusCard
            mainText="There are no events yet"
            subText="It's time to explore extraordinary events with Tutadoo."
          >
            <Button className="text-purple-800 py-0">Create an event</Button>
          </StatusCard>
        ) : (
          <Box>
            {data.events?.map((ev) => (
              <LongEventCard
                onClick={() => {
                  navigate(`/organization/events/dashboard/${ev?.id}`);
                }}
                key={`${ev?.id}`}
                {...ev}
              />
            ))}
          </Box>
        )}
      </Box>
    </TabContainer>
  );
};

export default EventsTab;
