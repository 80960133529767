export function filterArrOfObj(term = '', arr = [], banned = ['edit', 'no']) {
  if (!Array.isArray(arr)) return [];
  if (!term) return arr;

  const handleFilter = (o: Object) => {
    return Object.keys(o).some((k) => {
      if (banned?.includes(k)) {
        return false;
      }

      if (o[k] === null || o[k] === 'undefined') {
        return false;
      } else if (Array.isArray(o[k])) {
        const result = o[k].filter((obj) => handleFilter(obj));
        return !!result.length;
      } else if (typeof o[k] === 'object') {
        return handleFilter(o[k]);
      } else if (typeof o[k] === 'string') {
        return o[k]?.toLowerCase()?.includes(term?.toLowerCase());
      } else {
        return false;
      }
    });
  };

  let filtered = arr?.filter((o) => handleFilter(o));

  return filtered;
}
