import MembersList from 'components/Members/MembersList';
import React from 'react';
import { IOrganization } from 'services/organizations';
import { TabContainer } from './common';

const MembersTab = ({ organizationInfo }: { organizationInfo: IOrganization }) => {
  return (
    <TabContainer>
      <MembersList organizationInfo={organizationInfo} />;
    </TabContainer>
  );
};

export default MembersTab;
