import React from 'react';
import {
  Box,
  Button,
  Form,
  FormExtendedEvent,
  FormField,
  Heading,
  Layer,
  Select,
  Text
} from 'grommet';
import { CloseIcon } from 'components/icons';
import AutoBoxMinH from 'components/AutoBoxMinH';
import InputControl from 'components/InputControl';
import { IOrganizationMember } from 'services/organizations';

function InviteMembersDrawer({
  onClose,
  isOpen,
  isEdit,
  initialValues,
  onSubmit
}: {
  onClose?: () => void;
  isOpen: boolean;
  isEdit?: boolean;
  initialValues?: IOrganizationMember;
  onSubmit: (value: IOrganizationMember) => void;
}) {
  const [formValue, setFormValue] = React.useState<IOrganizationMember>({ ...initialValues });
  const handleSubmit = (event: FormExtendedEvent<IOrganizationMember, Element>) => {
    onSubmit({
      first_name: event?.value?.first_name,
      last_name: event?.value?.last_name,
      role: event?.value?.role,
      email: event?.value.email,
      phone: event?.value.phone
    });
  };

  React.useEffect(() => {
    setFormValue({ ...initialValues });
  }, [initialValues]);

  return isOpen ? (
    <Layer full="vertical" position="right">
      <Form onChange={setFormValue} value={formValue} onSubmit={handleSubmit}>
        <Box fill style={{ minWidth: '480px' }}>
          <Box direction="row" align="center" as="header" height={{ min: '56px' }} justify="end">
            <Button style={{ background: 'transparent' }} onClick={onClose} icon={<CloseIcon />} />
          </Box>
          <Box flex height={{ max: '80vh' }} overflow="auto" pad="0 32px 100px">
            <AutoBoxMinH>
              <Heading level="3">Invite member</Heading>
              <Text margin={{ top: '16px' }}>Add and manage your team.</Text>

              <Box margin={{ top: '14px' }}>
                <InputControl
                  name="first_name"
                  label="First name"
                  error=""
                  inputProps={{ placeholder: 'First Name' }}
                />
              </Box>
              <Box margin={{ top: '14px' }}>
                <InputControl
                  name="last_name"
                  label="Last name"
                  placeholder="Last name"
                  inputProps={{ placeholder: 'Last Name' }}
                />
              </Box>
              <Box margin={{ top: '14px' }}>
                <FormField name="role" label="Add role">
                  <Select options={['manager', 'viewer']} name="role" placeholder="Add Role" />
                </FormField>
              </Box>
              <Box margin={{ top: '14px' }}>
                <InputControl name="email" label="Email" inputProps={{ placeholder: 'Email' }} />
              </Box>
              <Box margin={{ top: '14px' }}>
                <InputControl name="phone" label="phone" inputProps={{ placeholder: 'phone' }} />
              </Box>
            </AutoBoxMinH>
          </Box>

          <Box
            as="footer"
            border={{ side: 'top' }}
            pad="small"
            justify="center"
            direction="row"
            align="center"
          >
            <Button
              margin={{ right: '16px' }}
              style={{ width: '50%', background: '#F5F5F6', color: '#000' }}
              primary
              label="Cancel"
              onClick={onClose}
            />
            <Button
              style={{ width: '50%' }}
              primary
              label={isEdit ? 'Save changes' : 'Send invite'}
              type="submit"
            />
          </Box>
        </Box>
      </Form>
    </Layer>
  ) : null;
}

InviteMembersDrawer.propTypes = {};

export default InviteMembersDrawer;
