import PayoutCards from 'components/Payouts/PayoutCards';
import React from 'react';
import { TabContainer, TabProps } from "./common";

const PayoutsTab = ({ organizationInfo }: TabProps) => {
    return (
      <TabContainer>
        <PayoutCards organizationInfo={organizationInfo}  />
      </TabContainer>
    );
  };

  export default PayoutsTab;