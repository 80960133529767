import React from 'react';
import { Box, RadioButton, Spinner, Text } from 'grommet';
import { IconButton } from 'grommet-controls';
import { PencilIcon, TrashIcon } from '../icons';
import tw from 'twin.macro';
import { IPayoutMethod, OrganizationService } from 'services/organizations';
import { useMutation } from '@apollo/client';
import useToast from '../../hooks/useToast';
import useDisclosure from '../../hooks/useDisclosure';
import AddPayoutDrawer from './AddPayoutDrawer';

const Divider = tw.div`w-full mx-auto h-0 my-2 border border-gray-200`;

const iconBtnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100px',
  width: '48px',
  height: '48px',
  padding: '12px',
  marginRight: '10px'
};

const InfoTextBox = ({ label, value }: { label: string; value: string }) => (
  <Text margin={{ top: '12px', bottom: '12px' }} size="small">
    <Text size="small" margin={{ right: '4px' }} color="#62656D" as="span">
      {label}
    </Text>
    <Text size="small" weight={'bold'} color="#000000" as="span">
      {value}
    </Text>
  </Text>
);

function PayoutItem(props: IPayoutMethod) {
  const { renderToast, toast, renderLoader } = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure({});

  const { id, account_name, account_number, branch, swift_code = '', organization_id } = props;

  const [deletePayout, { loading }] = useMutation<
    { payoutMethod: IPayoutMethod },
    { payoutId: string }
  >(OrganizationService.deletePayout, {
    variables: { payoutId: id },
    onError: (error) => toast.error(error?.message),
    update(store, { data }) {
      const payoutData = store.readQuery<{ payoutMethods: IPayoutMethod[] }>({
        query: OrganizationService.getAllPayouts(organization_id)
      });

      console.log({ payoutData, data: data.payoutMethod, organization_id });
      store.writeQuery<{ payoutMethods: IPayoutMethod[] }>({
        query: OrganizationService.getAllPayouts(organization_id),
        data: {
          payoutMethods: payoutData?.payoutMethods.filter((pm) => pm.id !== data.payoutMethod.id)
        }
      });
    }
  });

  const [updatePayout, { loading: updating }] = useMutation<
    { payoutMethod: IPayoutMethod },
    {
      payoutId: string;
      set: IPayoutMethod;
    }
  >(OrganizationService.updatePayout, {
    onCompleted: () => onClose(),
    onError: (err) => toast.error(err?.message)
  });

  const handleUpdate = (value: IPayoutMethod) => {
    delete value?.__typename;
    updatePayout({
      variables: { payoutId: id, set: value }
    });
  };

  return (
    <Box
      pad={'20px'}
      height={{ min: '228px' }}
      width="100%"
      margin={{ top: '10px', bottom: '10px' }}
      border="all"
      style={{ position: 'relative' }}
    >
      <Box direction="row" width="100%" justify="between">
        <RadioButton name="accountName" label="Account name" />
        <Box direction="row">
          <IconButton onClick={onOpen} style={iconBtnStyle} size="large" icon={<PencilIcon />} />
          <IconButton
            onClick={() => deletePayout()}
            style={iconBtnStyle}
            size="large"
            icon={<TrashIcon />}
          />
        </Box>
      </Box>
      <Divider />
      <InfoTextBox label="Name on account" value={account_name} />
      <InfoTextBox label="Address" value={branch} />
      <InfoTextBox label="Account number" value={account_number} />
      <InfoTextBox label={'Swift Code'} value={swift_code} />
      {loading && (
        <Box style={{ position: 'absolute', inset: 0, background: 'rgba(0,0,0,0.5)' }}>
          <Spinner />
        </Box>
      )}
      {renderToast()}
      {isOpen && (
        <AddPayoutDrawer
          initialValues={props}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleUpdate}
          isEdit
        />
      )}
      {renderToast()}
      {renderLoader(updating, 'Updating Payout Method...')}
    </Box>
  );
}

PayoutItem.propTypes = {};

export default PayoutItem;
