import { Box, Text } from 'grommet';
import moment from 'moment';
import React from 'react';
import { IEvent } from 'services/events';
import { Image } from 'tutadoo-design-system/src/components';
import { Button } from 'tutadoo-design-system/src/components/Button';
import { styled } from 'twin.macro';
import useResponsive from '../hooks/useResponsive';

const Img = styled(Image)`
  height: 140px;
  width: 280px;
  border-radius: 20px;
`;
function LongEventCard({
  image_url,
  image,
  name,
  venue,
  organizer,
  start_date,
  onClick
}: IEvent & { onClick?: () => void }) {
  const { screen } = useResponsive();
  return (
    <Box
      margin={{ top: '12px', bottom: '12px' }}
      pad={'12px'}
      height={{ min: '164px' }}
      border
      round="8px"
      direction="row"
      justify="between"
      align="center"
      wrap
      onClick={onClick}
    >
      <Box direction="row" align="center" className=" flex-wrap sm:flex-nowrap w-full sm:w-auto">
        <Img className="w-full sm:w-auto mr-0 sm:mr-4" src={image || image_url} />
        <Box className="my-4 sm:my-0">
          <Text weight={'bold'} size="18px">
            {name}
          </Text>
          <Box margin={{ top: '12px', bottom: '12px' }}>
            <Text>{moment(start_date).format()}</Text>
            <Text>{venue?.address_line1}</Text>
          </Box>

          <Text>
            by <b>{organizer?.name}</b>
          </Text>
        </Box>
      </Box>
      <Box direction="row" className="my-4 flex-wrap w-full sm:w-auto">
        <Button
          color={'#512E8D'}
          className="w-full sm:w-auto  bg-gray-200 sm:bg-transparent"
          label="Event overview"
          margin={{ bottom: screen.isMobile ? '16px' : '0px' }}
        />
        <Button className="w-full sm:w-auto" primary label="View tickets" />
      </Box>
    </Box>
  );
}

LongEventCard.propTypes = {};

export default LongEventCard;
