import { Table, TableHeader, TableRow, TableCell, TableBody, Box, Text, Pagination } from 'grommet';
import React, { CSSProperties, useEffect, useState } from 'react';

import { filterArrOfObj } from 'utils/search';

const thProps: CSSProperties = {
  fontSize: '12px',
  whiteSpace: 'nowrap',
  fontWeight: '600',
  lineHeight: '16px',
  color: '#62656D'
};

const tdProps: CSSProperties = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  color: '#000',
  whiteSpace: 'nowrap',
  padding: '5px 0px'
};

export type TableProps = {
  data: any[];
  itemsPerPage: number;
  filterTerm: string;
  columns: any[];
  hideSN: boolean;
  onRowClick?: (e: any) => void;
  emptyMessage?: React.ReactNode;
};

function SmartTable({
  data,
  itemsPerPage,
  filterTerm,
  columns,
  onRowClick,
  hideSN,
  emptyMessage
}: TableProps) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const handlePageClick = ({ page }) => {
    const newOffset = (page * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    let filterItems = filterArrOfObj(filterTerm, data);
    setCurrentItems(filterItems.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filterItems.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filterTerm, data]);

  return (
    <>
      <Box
        margin={{ top: '32px' }}
        overflow={{ horizontal: !!currentItems?.length ? 'scroll' : 'auto' }}
        style={{ borderRadius: '8px 8px 0px 0px' }}
      >
        <Table width={'100%'}>
          <TableHeader>
            <TableRow style={{ background: '#F5F5F6' }}>
              {!hideSN && <TableCell> </TableCell>}
              {Array.isArray(columns) &&
                columns.map((col) => (
                  <TableCell
                    style={{
                      ...thProps,
                      ...col?.thStyle
                    }}
                    key={col?.key}
                  >
                    {col.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {!!currentItems?.length &&
              currentItems?.map((row: any, i: number) => {
                return (
                  <TableRow
                    css={'cursor:pointer;  border-bottom:1px solid #EBECED'}
                    onClick={() => onRowClick(row)}
                    key={row?._id}
                  >
                    {!hideSN && (
                      <TableCell style={{ ...tdProps }}>{itemOffset + (i + 1)}.</TableCell>
                    )}
                    {Array.isArray(columns) &&
                      columns.map((col) => (
                        <TableCell style={{ ...tdProps, ...col?.style }}>
                          {col.func ? col.func(row[col.key || ''], row, tdProps) : row[col.key]}
                        </TableCell>
                      ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {!!!currentItems?.length && (
          <Box width="100%" margin={{ top: '20px' }}>
            <Text>
              {!!data?.length ? 'No result for this search' : emptyMessage || 'Nothing Is here yet'}
            </Text>
          </Box>
        )}
      </Box>
      <Box direction="row" justify="center">
        {!!currentItems?.length && (
          <Pagination
            numberItems={data?.length}
            page={pageCount}
            margin="small"
            onChange={handlePageClick}
          />
        )}
      </Box>
    </>
  );
}

SmartTable.defaultProps = {
  itemsPerPage: 10,
  filterTerm: ''
};

export default SmartTable;
