import { useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import BillingLists from 'components/BillingLists';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import InfoCard from 'components/InfoCard';
import { ActivePlanCard } from 'components/Subscribtions/PlanCard';
import { navigate } from 'gatsby';
import { Box, Tab, Tabs, ThemeContext } from 'grommet';
import React from 'react';
import { IOrganization, OrganizationService } from 'services/organizations';
import { dummyPlans } from '../../constants/dummy.constants';
import tabPages from './TabItems';
import { TabContainer } from './TabItems/common';

const Subscribtions = () => {
  return (
    <Box margin="20px 115px" width={{ max: '1300px' }}>
      <ActivePlanCard {...dummyPlans[0]} />
      <BillingLists />
    </Box>
  );
};

const TabItems = {
  events: {
    label: 'Events',
    component: tabPages.Events
  },
  info: {
    label: 'Basic Info',
    component: tabPages.BasicInfo
  },
  members: {
    label: 'Members',
    component: tabPages.Members
  },
  payouts: {
    label: 'Payouts',
    component: tabPages.Payouts
  },
  subscriptions: {
    label: 'Subscriptions',
    component: Subscribtions
  }
};

function OrganizationDashboard({ orgId, tab, basepath }: RouteComponentProps<{ orgId: string, tab:string, basepath:string }>) {
  const { loading, data } = useQuery<{ organizations_by_pk: IOrganization }>(
    OrganizationService.getOne(orgId)
  );
  const { organizations_by_pk: organization } = data || {};

  const getIndex = () =>
  Object.keys(TabItems).findIndex((tabName) => tab?.toLowerCase() === tabName.toLowerCase());
   const tabIndex = getIndex();

React.useEffect(() => {
  if (typeof tab === 'undefined') {
    navigate(`/${basepath}/${orgId}/events`);
  }
}, [tab]);

  return (
    <UserDashboard>
      <TabContainer>
        {loading ? (
          <InfoCard isLoading />
        ) : (
          <InfoCard
            title={organization?.name}
            profileImage={organization?.image_url}
            subtitle={organization?.email}
            sublabel={'Organizer account'}
          />
        )}
      </TabContainer>
      <ThemeContext.Extend
        value={{
          tabs: {
            header: {
              extend: `
                max-width:864px;
                width:100%;
                 margin:0 auto;
                 justify-content:flex-start;
               
             `
            }
          }
        }}
      >
        <Tabs activeIndex={tabIndex} className="px-4" alignControls="start">
          {Object.keys(TabItems).map((tabKey) => {
            const { label, component: Component } = TabItems[tabKey];

            return (
              <Tab
                className="text-gray-700 text-base font-semibold leading-[24px] mx-4 "
                //plain={true}
                onClick={() => navigate(`/${basepath}/${orgId}/${tabKey}`)}
                key={tabKey}
                title={label}
              >
                <Component organizationInfo={organization} />
              </Tab>
            );
          })}
        </Tabs>
      </ThemeContext.Extend>
    </UserDashboard>
  );
}

OrganizationDashboard.propTypes = {};

export default OrganizationDashboard;
