import { useMutation, useQuery } from '@apollo/client';
import { UserLightIcon } from 'components/icons';
import InviteMembersDrawer from 'components/Members/InviteMembersDrawer';
import SmartTable from 'components/SmartTable';
import StatusCard from 'components/StatusCard';
import { Box, Heading, Spinner, Text } from 'grommet';
import useToast from '../../hooks/useToast';
import React, { CSSProperties } from 'react';
import { IOrganization, IOrganizationMember, OrganizationService } from 'services/organizations';
import { Button } from 'tutadoo-design-system/src/components/Button';
import noUserImg from '../../assets/noUser.png';
import useDisclosure from '../../hooks/useDisclosure';
import MembersTableActions from './MembersTableActions';

function MembersList({ organizationInfo }: { organizationInfo: IOrganization }) {
  const { isOpen, onClose, onOpen } = useDisclosure({});
  const { toast, renderToast, renderLoader } = useToast();
  const TableColumns = [
    {
      label: 'Name',
      func: (_: string, row: IOrganizationMember, tdProps: CSSProperties) => (
        <Box direction="row" align="center">
          <UserLightIcon style={{ marginRight: '10px' }} />{' '}
          <Text style={tdProps}>{`${row?.first_name} ${row?.last_name}`}</Text>
        </Box>
      )
    },
    {
      label: 'Role',
      key: 'role'
    },
    {
      label: 'Email',
      key: 'email'
    },
    {
      label: '',
      func: (_: string, row: IOrganizationMember) => <MembersTableActions row={row} />
    }
  ];

  const { loading: loadingMembers, data } = useQuery<{
    organizationMembers: IOrganizationMember[];
  }>(OrganizationService.getAllMembers(organizationInfo?.id), {
    onError: (err) => toast.error(err?.message)
  });

  const [inviteMember, { loading, data: invite }] = useMutation<
    {
      organizationMember: IOrganizationMember;
    },
    { newMember: IOrganizationMember }
  >(OrganizationService.inviteMember, {
    onCompleted: () => {
      toast.success(`invite sent to ${invite?.organizationMember?.first_name}`);
      onClose();
    },
    onError: (err) => toast.error(err?.message),
    refetchQueries: [{ query: OrganizationService.getAllMembers(organizationInfo?.id) }]
  });

  const handleSubmit = (value: IOrganizationMember) => {
    value.target_id = organizationInfo.id;
    inviteMember({ variables: { newMember: value } });
  };

  return (
    <Box>
      {loadingMembers ? (
        <Box height={{ min: '200px' }} justify="center" align="center">
          <Spinner />
          <Text textAlign="center">Fetching Members Info</Text>
        </Box>
      ) : !!!data?.organizationMembers?.length ? (
        <StatusCard
          image={noUserImg}
          mainText="You have no members yet"
          subText="It's time to add your team."
        >
          <Button
            style={{ height: '48px' }}
            margin={{ top: '24px' }}
            primary
            label="invite members"
            onClick={onOpen}
          />
        </StatusCard>
      ) : (
        <Box>
          <Box direction="row" justify="between" align="center">
            <Heading level={'4'}>Members</Heading>
            <Button onClick={onOpen} primary label="Invite new member" />
          </Box>
          <SmartTable
            columns={TableColumns}
            data={data?.organizationMembers}
            itemsPerPage={20}
            filterTerm=""
            hideSN
            onRowClick={() => {}}
          />
        </Box>
      )}
      <InviteMembersDrawer isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} />
      {renderToast()}
      {renderLoader(loading, 'Inviting New Member')}
    </Box>
  );
}

MembersList.propTypes = {};

export default MembersList;
