import { Button } from 'grommet';
import SmartTable from 'components/SmartTable';
import React from 'react';
import { DownloadIcon } from 'components/icons';
import { dummyBilling } from '../constants/dummy.constants';
import moment from 'moment';

function BillingLists() {
  const tableColumns = [
    {
      key: 'date',
      label: 'Date',
      func: (val: string) =>moment(val).format("L h:mma")
        
    },
    {
      key: 'plan',
      label: 'Plan'
    },
    {
      key: 'amount',
      label: 'Amount',
      func: (val: string) => <Button reverse icon={<DownloadIcon />} label={val} />
    }
  ];
  return <SmartTable columns={tableColumns} data={dummyBilling} hideSN />;
}

BillingLists.propTypes = {};

export default BillingLists;
