import React, { SVGProps } from 'react';

const UserLightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="#F5F5F6"
    />
    <path
      d="M16 16C18.21 16 20 14.21 20 12C20 9.79 18.21 8 16 8C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16ZM16 18C13.33 18 8 19.34 8 22V23C8 23.55 8.45 24 9 24H23C23.55 24 24 23.55 24 23V22C24 19.34 18.67 18 16 18Z"
      fill="#9D9FA4"
    />
  </svg>
);

export default UserLightIcon;
