import StatusCard from 'components/StatusCard';
import { Box, Heading, Spinner, Text } from 'grommet';
import noPayoutImg from '../../assets/noPayout.png';
import React from 'react';
import { Button } from 'tutadoo-design-system/src/components/Button';
import useDisclosure from '../../hooks/useDisclosure';
import AddPayoutDrawer from './AddPayoutDrawer';
import PayoutItem from './PayoutItem';
import { useMutation, useQuery } from '@apollo/client';
import { OrganizationService, IPayoutMethod, IOrganization } from 'services/organizations';
import useToast from '../../hooks/useToast';

function PayoutCards({ organizationInfo }: { organizationInfo: IOrganization }) {
  const { isOpen, onClose, onOpen } = useDisclosure({});
  const { renderToast, toast, renderLoader } = useToast();

  const [addPayoutMethod, { loading }] = useMutation<
    { insert_organization_account_one: IPayoutMethod },
    { newPayout: IPayoutMethod }
  >(OrganizationService.addPayoutMethod, {
    update(store, { data }) {
      const payoutData = store.readQuery<{ payoutMethods: IPayoutMethod[] }>({
        query: OrganizationService.getAllPayouts(organizationInfo?.id)
      });
      store.writeQuery<{ payoutMethods: IPayoutMethod[] }>({
        query: OrganizationService.getAllPayouts(organizationInfo.id),
        data: { payoutMethods: [...payoutData.payoutMethods, data.insert_organization_account_one] }
      });
    },
    onCompleted: () => onClose()
  });

  const { loading: loadingPayouts, data } = useQuery<{ payoutMethods: IPayoutMethod[] }>(
    OrganizationService.getAllPayouts(organizationInfo?.id)
  );

  const handleSubmit = (value: IPayoutMethod) => {
    value.organization_id = organizationInfo.id;
    addPayoutMethod({ variables: { newPayout: value } })
      .then(() => {
        toast.success('New Payout method added succesfully');
      })
      .catch((err) => {
        toast.error(err?.message, 'Failed to add payout method');
      });
  };

  return (
    <Box>
      {loadingPayouts ? (
        <Box height={{ min: '200px' }} justify="center" align="center">
          <Spinner />
          <Text textAlign="center">Fetching Payouts Methods...</Text>
        </Box>
      ) : !!!data?.payoutMethods?.length ? (
        <StatusCard
          image={noPayoutImg}
          mainText="No payout method set up"
          subText="Set up a payment method to get pais."
        >
          <Button
            style={{ height: '48px' }}
            margin={{ top: '24px' }}
            primary
            label="Add payout method"
            onClick={onOpen}
          />
        </StatusCard>
      ) : (
        <Box>
          <Box margin={{ bottom: '24px' }} direction="row" justify="between">
            <Heading level="4">Your payout method</Heading>

            <Button onClick={onOpen} primary label="Add payout method" />
          </Box>
          {data?.payoutMethods?.map((payout) => (
            <PayoutItem key={payout?.id} {...payout} />
          ))}
        </Box>
      )}

      {isOpen && <AddPayoutDrawer isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} />}
      {renderToast()}
      {renderLoader(loading, 'Adding New Payout Method...')}
    </Box>
  );
}

PayoutCards.propTypes = {};

export default PayoutCards;
