import React from 'react';
import { Box, Button, Form, FormExtendedEvent, Heading, Layer, Text } from 'grommet';
import { CloseIcon } from 'components/icons';
import AutoBoxMinH from 'components/AutoBoxMinH';

import InputControl from 'components/InputControl';
import { Checkbox } from 'tutadoo-design-system/src/components';
import { Link } from '@reach/router';
import { IPayoutMethod } from 'services/organizations';

function AddPayoutDrawer({
  onClose,
  isOpen,
  isEdit,
  initialValues,
  onSubmit
}: {
  onClose?: () => void;
  isOpen: boolean;
  isEdit?: boolean;
  initialValues?: IPayoutMethod;
  onSubmit: (value: IPayoutMethod) => void;
}) {
  const [formValue, setFormValue] = React.useState<IPayoutMethod>({ ...initialValues });

  const handleSubmit = (event: FormExtendedEvent<IPayoutMethod, Element>) => {
    onSubmit(event.value);
  };

  React.useEffect(() => {
    setFormValue({ ...initialValues });
  }, [initialValues]);

  return isOpen ? (
    <Layer full="vertical" position="right">
      <Box fill style={{ maxWidth: '740px', width: '100%' }}>
        <Form
          onChange={(nextVal) => setFormValue(nextVal)}
          value={formValue}
          onSubmit={handleSubmit}
        >
          <Box direction="row" align="center" as="header" height={{ min: '56px' }} justify="end">
            <Button style={{ background: 'transparent' }} onClick={onClose} icon={<CloseIcon />} />
          </Box>
          <Box flex height={{ max: '80vh' }} overflow="auto" pad="0 32px 100px">
            <AutoBoxMinH>
              <Box>
                <Heading level="3">{ isEdit ? "Edit" :"Add"} payout method</Heading>
                <Text margin={{ top: '16px' }}>Enter bank account details</Text>

                <Text size="medium" weight={'bold'} margin={{ top: '16px' }}>
                  Personal details
                </Text>

                <Box align="center" direction="row" margin={{ top: '14px' }}>
                  <InputControl
                    name="first_name"
                    label="First name"
                    error=""
                    width="50%"
                    inputProps={{ placeholder: 'First Name' }}
                    className="mr-4"
                  />
                  <InputControl
                    name="last_name"
                    label="Last name"
                    width="50%"
                    placeholder="Last name"
                    inputProps={{ placeholder: 'Last Name' }}
                  />
                </Box>

                <Box margin={{ top: '14px' }}>
                  <InputControl
                    name="organization_position"
                    label="Position in organisation"
                    inputProps={{ placeholder: 'Position in organisation' }}
                  />
                </Box>
                <Box align="center" direction="row" margin={{ top: '14px' }}>
                  <InputControl
                    width="50%"
                    name="email"
                    label="Email"
                    inputProps={{ placeholder: 'Email' }}
                    className="mr-4"
                  />
                  <InputControl
                    name="phone"
                    label="Mobile number"
                    width="50%"
                    inputProps={{ placeholder: 'Mobile number' }}
                  />
                </Box>

                <Text size="medium" weight={'bold'} margin={{ top: '16px' }}>
                  Funds transfer details
                </Text>
                <Box align="center" direction="row" margin={{ top: '14px' }}>
                  <InputControl
                    width="50%"
                    name="bank_name"
                    label="Bank name"
                    inputProps={{ placeholder: 'Bank name' }}
                    className="mr-4"
                  />
                  <InputControl
                    name="branch"
                    width="50%"
                    label="Branch"
                    inputProps={{ placeholder: 'XXX' }}
                  />
                </Box>
                <Box align="center" direction="row" margin={{ top: '14px' }}>
                  <InputControl
                    width="50%"
                    name="account_name"
                    label="Bank name"
                    inputProps={{ placeholder: 'Account name' }}
                    className="mr-4"
                  />
                  <InputControl
                    name="account_number"
                    width="50%"
                    label="Account number"
                    inputProps={{ placeholder: 'XXXXXXXXXXXXXX' }}
                  />
                </Box>
                <Box>
                  <InputControl
                    name="swift_code"
                    label="Swift Code"
                    inputProps={{ placeholder: 'XXX XX XX XXX' }}
                  />
                </Box>

                <Box>
                  <Checkbox
                    name="acceptTerms"
                    label="I confirm that the information here given is true and accept the  terms and conditions of use as  stipulated"
                  />
                </Box>

                <Text margin={{ top: '20px' }}>
                  Need more information?
                  <Link style={{ color: '#8264EE' }} to="/terms">
                    Terms & Conditions
                  </Link>
                </Text>
              </Box>
            </AutoBoxMinH>
          </Box>
          <Box
            as="footer"
            border={{ side: 'top' }}
            pad="small"
            justify="center"
            direction="row"
            align="center"
          >
            <Button
              margin={{ right: '16px' }}
              style={{ width: '50%', background: '#F5F5F6', color: '#000' }}
              primary
              label="Cancel"
              onClick={onClose}
            />
            <Button
              style={{ width: '50%' }}
              primary
              label={isEdit ? 'Save changes' : 'Create'}
              type="submit"
            />
          </Box>
        </Form>
      </Box>
    </Layer>
  ) : null;
}

AddPayoutDrawer.propTypes = {};

export default AddPayoutDrawer;
