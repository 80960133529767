import { useMutation } from '@apollo/client';
import { ElipsesV } from 'components/icons';
import InviteMembersDrawer from 'components/Members/InviteMembersDrawer';
import { Menu, Spinner } from 'grommet';
import React from 'react';
import { IOrganizationMember, OrganizationService } from 'services/organizations';
import useDisclosure from '../../hooks/useDisclosure';
import useToast from '../../hooks/useToast';

function MembersTableActions({ row }: { row: IOrganizationMember }) {
  const { isOpen, onOpen, onClose } = useDisclosure({});
  const { renderLoader, renderToast, toast } = useToast();

  const [updateMemberInfo, { loading }] = useMutation<
    {},
    { memberId: string; set: IOrganizationMember }
  >(OrganizationService.updateMember, {
    onCompleted: () => onClose(),
    onError: (err) => toast.error(err?.message)
  });

  const [deleteMemberInfo, { loading: deleting }] = useMutation<{
    organizationMember: IOrganizationMember;
  }>(OrganizationService.deleteMember(row?.id), {
    onCompleted: () => toast.success(`member ${row?.first_name} removed`),
    onError: (err) => toast.error(err?.message),
    refetchQueries: [{ query: OrganizationService.getAllMembers(row?.target_id) }]
  });

  const handleUpdate = (value: IOrganizationMember) => {
    updateMemberInfo({
      variables: {
        set: value,
        memberId: row?.id
      }
    });
  };

  return (
    <>
      <Menu
        dropProps={{
          align: { top: 'bottom', left: 'left' },
          elevation: 'xlarge'
        }}
        icon={<ElipsesV />}
        label={deleting ? <Spinner /> : ''}
        style={{
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          borderRadius: '100px'
        }}
        items={[
          {
            label: 'Edit member',
            onClick: onOpen
          },
          { label: 'Delete member', onClick: () => deleteMemberInfo() }
        ]}
      />
      {isOpen && (
        <InviteMembersDrawer
          isOpen={isOpen}
          onClose={onClose}
          initialValues={row}
          isEdit={true}
          onSubmit={handleUpdate}
        />
      )}
      {renderToast()}
      {renderLoader(loading, 'Updating MemberInfo Props')}
    </>
  );
}

export default MembersTableActions;
