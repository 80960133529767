import { useMutation } from '@apollo/client';
import CreateOrganization from 'components/CreateOrganization';
import {
    Layer, Spinner, Text
} from 'grommet';
import React from 'react';
import {
    ICreateOrganizationPayload, IOrganization, OrganizationService
} from 'services/organizations';
import useToast from '../../../hooks/useToast';
import { TabProps, TabContainer } from "./common"



const BasicInfoTab = ({ organizationInfo }: TabProps) => {
    const { renderToast, toastify } = useToast();
    const [updateOrganization, { loading }] = useMutation<
      { update_organizations_by_pk: IOrganization },
      { set: ICreateOrganizationPayload; orgId: string }
    >(OrganizationService.updateOrgById);
  
    const handleSave = (value: IOrganization) => {
      delete value?.__typename;
      delete value?.id;
  
      updateOrganization({ variables: { set: value, orgId: organizationInfo?.id } })
        .then(() => {
          toastify({ isVisible: true, title: 'Updated Successfully', message: '', status: 'normal' });
        })
        .catch((err) => {
          toastify({
            isVisible: true,
            title: 'Failed To Update Organizations',
            message: err?.message,
            status: 'critical'
          });
        });
    };
  
    return (
      <TabContainer>
        <CreateOrganization
          initialValues={organizationInfo}
          btnLabel="Save Changes"
          onSave={handleSave}
        />
        {loading && (
          <Layer
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: '300px',
              height: '200px'
            }}
          >
            <Spinner />
            <Text> Updating Organization details...</Text>
          </Layer>
        )}
        {renderToast()}
      </TabContainer>
    );
  };

  export default BasicInfoTab;